<template>
	<el-scrollbar class="seller-scrollbar">
		<el-card class="seller-card">
			<el-breadcrumb separator-class="el-icon-arrow-right">
				<el-breadcrumb-item :to="{ path: '/' }">{{ $t('seller.breadcrumb.seller') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'marketingtools' }">{{ $t('seller.breadcrumb.marketPlatform') }}</el-breadcrumb-item>
				<el-breadcrumb-item :to="{ name: 'couponList', query: { type: 3 } }">
					{{ $t('seller.breadcrumb.coupon') }}
				</el-breadcrumb-item>
				<el-breadcrumb-item>{{ $t('seller.breadcrumb.addSahreCoupon') }}</el-breadcrumb-item>
			</el-breadcrumb>
		</el-card>
		<el-form :model="couponForm" :rules="couponRulse" ref="couponForm" label-width="128px">
			<el-card class="seller-card">
				<template slot="header">{{ $t('seller.coupon.paneTitle2') }}</template>
				<el-form-item :label="$t('seller.formItem.name')" prop="name">
					<el-input v-model="couponForm.name" class="w350"
						:placeholder="$t('seller.placeholder.couponName')" maxlength="10"
						show-word-limit></el-input>
				</el-form-item>
				<el-form-item :label="$t('seller.coupon.paneTitle1')">
					<el-radio-group v-model="couponForm.channel_type">
						<el-radio :label="0">
							<span>{{ $t('seller.coupon.channelType1') }}</span>
							<el-popover placement="bottom" width="300" trigger="hover"
								:content="$t('seller.coupon.channelType1-1')">
								<i class="el-icon-question el-icon--right" slot="reference"></i>
							</el-popover>
						</el-radio>
						<el-radio :label="1">
							<span>{{ $t('seller.coupon.channelType2') }}</span>
							<el-popover placement="bottom" width="300" trigger="hover"
								:content="$t('seller.coupon.channelType2-1')">
								<i class="el-icon-question el-icon--right" slot="reference"></i>
							</el-popover>
						</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.shareFollowShow')">
					<el-checkbox v-model="couponForm.is_follow" :true-label="1" :false-label="0">{{ $t('seller.coupon.followShop') }}</el-checkbox>
				</el-form-item>
			</el-card>
			<el-card class="seller-card">
				<template slot="header">{{ $t('seller.coupon.sharersCoupon') }}</template>
				<el-form-item :label="$t('seller.tableHead.couponType')" prop="sub_type">
					<el-radio-group v-model="couponForm.sub_type">
						<el-radio v-for="item in Object.keys(couponType)" :key="item" :label="item">{{ couponType[item] }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="couponForm.sub_type == 2" :label="$t('seller.formItem.goodsRange')" prop="goods_ids">
					<div>
						<el-button type="primary" @click="handleGoodsShow(1)">{{ $t('seller.actions.selectGoods') }}</el-button>
						<span>（{{ $t('seller.actions.selected') }}{{ couponForm.goods_ids.length }}{{ $t('seller.unit.pcs') }}）</span>
					</div>
					<p>{{ $t('seller.coupon.paneTips4') }}</p>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.useTime')" prop="use_end_time">
					<el-date-picker v-model="datetimes" type="daterange" range-separator="-"
						:start-placeholder="$t('seller.placeholder.originDate')" value-format="timestamp"
						:end-placeholder="$t('seller.placeholder.endDate')" :picker-options="startPickerOptions"
						:default-time="['00:00:00', '23:59:59']" @change="handleChangeTime">
					</el-date-picker>
					<span class="tips"><i class="el-icon-warning el-icon--left"></i>{{ $t('seller.coupon.paneTips1') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.tableHead.exfiltrateTime')" prop="exfiltrate_time">
					<el-date-picker v-model="exfiltrateTime" type="date" value-format="timestamp"
						:picker-options="exfiltratePickerOptions"
						:placeholder="$t('seller.placeholder.selectDate')" @change="handleChangeExfiltrateTime">
					</el-date-picker>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.couponAmount')" prop="money">
					<el-input v-model="couponForm.money" class="w350"
						:placeholder="$t('seller.placeholder.couponAmount')"
						oninput="value=value.replace(/[^\d]/g,'')"></el-input>
					<span class="group-after">{{ $t('seller.unit.yuan') }}</span>
					<span class="tips" style="display: inline-block;line-height: 20px;vertical-align: top;">
						<div v-if="couponForm.sub_type"><i class="el-icon-warning el-icon--left"></i>{{ $t('seller.coupon.paneTips7-1') }}{{ couponForm.sub_type | typeFilter({ 1: '10.0', 2: '9' }) }}{{ $t('seller.coupon.paneTips7-2') }}</div>
						<div><i class="el-icon-warning el-icon--left"></i>{{ $t('seller.coupon.paneTips8-1') }}<span
								v-if="couponForm.sub_type">{{ $t('seller.coupon.paneTips8-2') }}{{ couponForm.sub_type | typeFilter({ 1: 0, 2: 5 }) }}{{ $t('seller.unit.yuan') }}</span></div>
					</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.useThreshold')" prop="quota_type">
					<el-radio-group v-model="couponForm.quota_type">
						<div>
							<el-radio :label="1">
								<span class="group-before">{{ $t('seller.unit.full') }}</span>
								<el-input v-model="couponForm.quota" class="w326"
									:placeholder="$t('seller.placeholder.useThreshold')"
									oninput="value=value.replace(/[^\d]/g,'')"></el-input>
								<span class="group-after">{{ $t('seller.unit.yuan') }}</span>
							</el-radio>
						</div>
						<div style="margin-top: 15px;">
							<el-radio :label="2">
								<span class="group-before">{{ $t('seller.unit.full') }}{{ parseInt(couponForm.money * 1) + 0.01 }}{{ $t('seller.unit.yuan') }}{{ $t('seller.coupon.paneTips9') }}</span>
							</el-radio>
						</div>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.circulation')" prop="give_num">
					<el-input v-model="couponForm.give_num" class="w350"
						:placeholder="$t('seller.placeholder.minSheet')"
						oninput="value=value.replace(/[^\d]/g,'')">
					</el-input>
					<span class="group-after">{{ $t('seller.unit.sheet') }}</span>
					<span class="tips"><i class="el-icon-warning el-icon--left"></i>{{ $t('seller.coupon.paneTips6') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.limitPerPerson')">
					<span class="group-after">{{ couponForm.limit_give_num }}{{ $t('seller.unit.sheet') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.shareNum')" prop="share_num">
					<el-select v-model="couponForm.share_num">
						<el-option :label="2" :value="2"></el-option>
						<el-option :label="3" :value="3"></el-option>
						<el-option :label="5" :value="5"></el-option>
						<el-option :label="10" :value="10"></el-option>
					</el-select>
					<span class="group-after">{{ $t('seller.unit.sheet') }}</span>
				</el-form-item>
			</el-card>
			<el-card class="seller-card">
				<template slot="header">{{ $t('seller.coupon.sharedByCoupon') }}</template>
				<el-form-item :label="$t('seller.tableHead.couponType')" prop="sub_coupon.sub_type">
					<el-radio-group v-model="couponForm.sub_coupon.sub_type">
						<el-radio v-for="item in Object.keys(couponType)" :key="item" :label="item">{{
						couponType[item] }}</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item v-if="couponForm.sub_coupon.sub_type == 2" :label="$t('seller.formItem.goodsRange')" prop="sub_coupon.goods_ids">
					<div>
						<el-button type="primary" @click="handleGoodsShow(2)">{{ $t('seller.actions.selectGoods') }}</el-button>
						<span>（{{ $t('seller.actions.selected') }}{{ couponForm.sub_coupon.goods_ids.length }}{{ $t('seller.unit.pcs') }}）</span>
					</div>
					<p>{{ $t('seller.coupon.paneTips4') }}</p>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.useTime')">
					<span v-if="datetimes">{{ couponForm.sub_coupon.use_begin_time | parseTime() }}</span>
					<span>-</span>
					<span v-if="datetimes">{{ couponForm.sub_coupon.use_end_time | parseTime() }}</span>
					<span class="tips"><i class="el-icon-warning el-icon--left"></i>{{ $t('seller.coupon.paneTips10') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.couponAmount')" prop="sub_coupon.money">
					<el-input v-model="couponForm.sub_coupon.money" class="w350"
						:placeholder="$t('seller.placeholder.couponAmount')"
						oninput="value=value.replace(/[^\d]/g,'')"></el-input>
					<span class="group-after">{{ $t('seller.unit.yuan') }}</span>
					<span class="tips"><i class="el-icon-warning el-icon--left"></i>{{ $t('seller.coupon.paneTips8-1') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.useThreshold')" prop="sub_coupon.quota_type">
					<el-radio-group v-model="couponForm.sub_coupon.quota_type">
						<div>
							<el-radio :label="1">
								<span class="group-before">{{ $t('seller.unit.full') }}</span>
								<el-input v-model="couponForm.sub_coupon.quota" class="w326"
									:placeholder="$t('seller.placeholder.useThreshold')"
									oninput="value=value.replace(/[^\d]/g,'')"></el-input>
								<span class="group-after">{{ $t('seller.unit.yuan') }}</span>
							</el-radio>
						</div>
						<div style="margin-top: 15px;">
							<el-radio :label="2">
								<span class="group-before">{{ $t('seller.unit.full') }}{{ couponForm.sub_coupon.money * 1 + 0.01 }}{{ $t('seller.unit.yuan') }}{{ $t('seller.coupon.paneTips9') }}</span>
							</el-radio>
						</div>
					</el-radio-group>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.circulation')" prop="sub_coupon.give_num">
					<el-input v-model="couponForm.sub_coupon.give_num" class="w350"
						:placeholder="$t('seller.validator.circulationMsg')"
						oninput="value=value.replace(/[^\d]/g,'')">
					</el-input>
					<span class="group-after">{{ $t('seller.unit.sheet') }}</span>
					<span class="tips" v-if="couponForm.give_num"><i
							class="el-icon-warning el-icon--left"></i>{{ $t('seller.coupon.paneTips11') }}({{ couponForm.give_num * couponForm.share_num * 1.5 }}{{ $t('seller.unit.sheet') }})</span>
					<span class="tips" v-else><i
							class="el-icon-warning el-icon--left"></i>{{ $t('seller.coupon.paneTips12') }}</span>
				</el-form-item>
				<el-form-item :label="$t('seller.formItem.limitPerPerson')">
					<span class="group-after">{{ couponForm.sub_coupon.limit_give_num }}{{ $t('seller.unit.sheet') }}</span>
				</el-form-item>
			</el-card>
		</el-form>
		<div class="page-bottom">
			<el-button @click="handleCouponSubmit" type="primary">{{ $t('seller.actions.confirmCreate2') }}</el-button>
		</div>
		<!-- 选择商品 -->
		<el-dialog :title="$t('seller.dialogTitle.goods')" width="960px" :visible.sync="dialogVisibleGoods"
			:before-close="handleCloseGoods" custom-class="seller-dialog">
			<el-scrollbar class="seller-dialog-scrollbar">
				<el-form size="mini" :inline="true">
					<el-form-item>
						<el-select v-model="screenForm.store_cate_id" clearable @change="handleSeach">
							<el-option v-for="(item, index) in shopCateOption" :key="index"
								:label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.goods_name" clearable
							:placeholder="$t('seller.placeholder.babyName')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.goods_code" clearable
							:placeholder="$t('seller.placeholder.babyCode')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.goods_id" clearable
							:placeholder="$t('seller.placeholder.babyId')"></el-input>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handleSeach">{{ $t('seller.actions.search') }}</el-button>
					</el-form-item>
				</el-form>
				<el-table v-loading="lisLoading" ref="goodsTable" :data="goodsList"
					size="small" :row-key="getRowKey" @selection-change="handleSelectionChange" class="seller-table">
					<el-table-column type="selection" :reserve-selection="true" width="45">
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.babyDescribe')" min-width="220px">
						<template slot-scope="scope">
							<div class="goods-item">
								<img class="pic" :src="scope.row.default_image" alt="">
								<div class="item-mian">
									<div class="title">{{ scope.row.goods_name }}</div>
								</div>
							</div>
						</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.unitPrice')">
						<template slot-scope="scope">￥{{ scope.row.price }}</template>
					</el-table-column>
					<el-table-column :label="$t('seller.tableHead.stock')" prop="stock"></el-table-column>
					<el-table-column :label="'30' + $t('seller.unit.day') + $t('seller.tableHead.totalSales')"
						prop="sales">
						<template slot-scope="scope">{{ scope.row.sales ? scope.row.sales : '0' }}</template>
					</el-table-column>
				</el-table>
				<pagination v-show="pageTotal > 0" :total="pageTotal" :page.sync="pageCurr"
					:limit.sync="pageSize" @pagination="getGoodsList"></pagination>
			</el-scrollbar>
			<div slot="footer">
				<el-button size="small" @click="handleCloseGoods">{{ $t('seller.actions.cancel') }}</el-button>
				<el-button type="primary" size="small" @click="handleToggleSelecting">{{ $t('seller.actions.confirm') }}</el-button>
			</div>
		</el-dialog>
	</el-scrollbar>
</template>

<script>
import Pagination from '@/components/seller/pagination'
import { getCouponGoods, createCoupon } from '@/api/seller/coupon';
export default {
	components: { Pagination },
	data() {
		var validateGoods = (rule, value, callback) => {
			if (value && value.length > 0) {
				callback()
			} else {
				callback(new Error(this.$t('seller.validator.minSelectGoods')))
			}
		}
		var validateExfiltrate = (rule, value, callback) => {
			if (value && this.couponForm.use_begin_time && value > this.couponForm.use_begin_time) {
				callback(new Error(this.$t('seller.validator.exfiltrateUseTime')))
			} else {
				callback()
			}
		}
		var validateMoney = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(this.$t('seller.validator.couponMoney')))
			} else if (value > 5000) {
				callback(new Error(this.$t('seller.validator.couponMoneyMax')))
			} else if (this.couponForm.sub_type === 2 && value < 5) {
				callback(new Error(this.$t('seller.validator.couponMoneyMin')))
			} else {
				callback()
			}
		}
		var validateQuotaType = (rule, value, callback) => {
			if (value === 1) {
				if (this.couponForm.quota === '' || parseInt(this.couponForm.quota) < 1) {
					callback(new Error(this.$t('seller.placeholder.positiveInteger')))
				} else if (parseInt(this.couponForm.quota) <= parseInt(this.couponForm.money)) {
					callback(new Error(this.$t('seller.placeholder.useThreshold')))
				} else {
					callback()
				}
			} else {
				this.couponForm.quota = ''
				callback()
			}
		}
		var validateSubQuotaType = (rule, value, callback) => {
			if (value === 1) {
				if (this.couponForm.sub_coupon.quota === '' || parseInt(this.couponForm.sub_coupon.quota) < 1) {
					callback(new Error(this.$t('seller.placeholder.positiveInteger')))
				} else if (parseInt(this.couponForm.sub_coupon.quota) <= parseInt(this.couponForm.sub_coupon.money)) {
					callback(new Error(this.$t('seller.placeholder.useThreshold')))
				} else {
					callback()
				}
			} else {
				callback()
			}
		}
		var validateGiveNum = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(this.$t('seller.validator.circulationMsg')))
			} else if (parseInt(value) < 1) {
				callback(new Error(this.$t('seller.placeholder.positiveInteger')))
			} else if (this.couponForm.sub_type && parseInt(value) > 100000) {
				callback(new Error(this.$t('seller.validator.circulationMax')))
			} else {
				callback()
			}
		}
		var validateSubGiveNum = (rule, value, callback) => {
			if (value === '') {
				callback(new Error(this.$t('seller.validator.circulationMsg')))
			} else if (parseInt(value) < 1) {
				callback(new Error(this.$t('seller.placeholder.positiveInteger')))
			} else if (this.couponForm.give_num && parseInt(value) < this.couponForm.give_num * this.couponForm.share_num * 1.5) {
				callback(new Error(this.$t('seller.validator.couponGiveNum') + this.couponForm.give_num * this.couponForm.share_num * 1.5 + this.$t('seller.unit.sheet')))
			} else {
				callback()
			}
		}
		return {
			couponType: this.$t('seller.filters.couponType'),
			couponForm: {
				type: 3,
				name: '',
				channel_type: 0,
				is_follow: 0,
				sub_type: '',
				goods_ids: [],
				use_begin_time: '',
				use_end_time: '',
				exfiltrate_time: '',
				money: '',
				quota_type: 1,
				quota: '',
				give_num: '',
				limit_give_num: 1,
				share_num: 3,
				sub_coupon: {
					sub_type: '',
					goods_ids: [],
					use_begin_time: '',
					use_end_time: '',
					money: '',
					quota_type: 1,
					quota: '',
					give_num: '',
					limit_give_num: 1
				},
			},
			couponRulse: {
				name: [{ required: true, message: this.$t('seller.validator.couponName'), trigger: 'blur' }],
				sub_type: [{ required: true, message: this.$t('seller.validator.couponType'), trigger: 'change' }],
				goods_ids: [{ required: true, validator: validateGoods, trigger: 'blur' }],
				use_end_time: [{ required: true, message: this.$t('seller.validator.couponName'), trigger: 'blur' }],
				exfiltrate_time: [{ validator: validateExfiltrate, trigger: 'change' }],
				money: [{ required: true, validator: validateMoney, trigger: 'blur' }],
				quota_type: [{ required: true, validator: validateQuotaType, trigger: 'change' }],
				give_num: [{ required: true, validator: validateGiveNum, trigger: 'blur' }],
				share_num: [{ required: true, message: this.$t('seller.validator.shareNum'), trigger: 'change' }],
				'sub_coupon.sub_type': [{ required: true, message: this.$t('seller.validator.couponType'), trigger: 'change' }],
				'sub_coupon.money': [{ required: true, validator: validateMoney, trigger: 'blur' }],
				'sub_coupon.quota_type': [{ required: true, validator: validateSubQuotaType, trigger: 'change' }],
				'sub_coupon.give_num': [{ required: true, validator: validateSubGiveNum, trigger: 'blur' }]
			},
			datetimes: '',
			startPickerOptions: {
				disabledDate: (time) => {
					return time.getTime() < new Date(new Date().getTime() - 86400000)
				}
			},
			exfiltrateTime: '',
			exfiltratePickerOptions: {
				disabledDate: (time) => {
					return time.getTime() < new Date(new Date().getTime() - 86400000)
				}
			},
			dialogVisibleGoods: false,
			shopCateOption: this.$t('seller.coupon.shopCateOption'),
			lisLoading: false,
			pageCurr: 1,
			pageSize: 5,
			pageTotal: 0,
			goodsType: 1,
			goodsList: [],
			checkIds: [],
			checkGoods: [],
			multipleSelection: [],
			screenForm: {
				goods_code: '',
				goods_id: '',
				goods_name: '',
				store_cate_id: '0',
			}
		}
	},
	methods: {
		handleChangeTime() {
			if (this.datetimes) {
				this.couponForm.use_begin_time = parseInt(this.datetimes[0] / 1000)
				this.couponForm.use_end_time = parseInt(this.datetimes[1] / 1000)
				this.couponForm.sub_coupon.use_begin_time = parseInt(this.datetimes[0] / 1000)
				this.couponForm.sub_coupon.use_end_time = parseInt(this.datetimes[1] / 1000)
			} else {
				this.couponForm.use_begin_time = ''
				this.couponForm.use_end_time = ''
			}
		},
		handleChangeExfiltrateTime() {
			if (this.exfiltrateTime) {
				this.couponForm.exfiltrate_time = parseInt(this.exfiltrateTime / 1000)
			} else {
				this.couponForm.exfiltrate_time = ''
			}
		},
		getGoodsList() {
			this.lisLoading = true
			let param = {
				...this.screenForm,
				type: '0',
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			};
			getCouponGoods(param).then(response => {
				this.goodsList = response.data.list;
				this.pageTotal = parseInt(response.data.total);
				this.lisLoading = false
				this.goodsList.forEach(item => {
					if (this.checkIds.includes(item.goods_id)) {
						this.$refs.goodsTable.toggleRowSelection(item)
						this.checkIds.splice(this.checkIds.indexOf(item.goods_id), 1)
					}
				})
			})
		},
		handleSeach(val) {
			this.pageCurr = 1;
			this.getGoodsList();
		},
		handleGoodsShow(type) {
			this.goodsType = type
			this.checkGoods = []
			this.pageCurr = 1
			if (this.goodsType === 1) {
				this.checkIds = JSON.parse(JSON.stringify(this.couponForm.goods_ids))
			} else if (this.goodsType === 2) {
				this.checkIds = JSON.parse(JSON.stringify(this.couponForm.sub_coupon.goods_ids))
			}
			this.dialogVisibleGoods = true
			this.$nextTick(() => {
				this.$refs.goodsTable.clearSelection()
				this.getGoodsList()
			})
		},
		handleCloseGoods() {
			this.dialogVisibleGoods = false;
		},
		getRowKey(row) {
			return row.goods_id
		},
		handleSelectionChange(val) {
			this.checkGoods = val
		},
		handleToggleSelecting() {
			let ids = [];
			this.checkGoods.forEach(item => {
				ids.push(item.goods_id);
			})
			ids = this.checkIds.concat(ids)
			if (ids.length > 100) {
				this.$message.error(this.$t('seller.errorMsg.appointGoodsMax'))
				return false
			}
			if (this.goodsType === 1) {
				this.couponForm.goods_ids = ids
			} else if (this.goodsType === 2) {
				this.couponForm.sub_coupon.goods_ids = ids
			}
			this.dialogVisibleGoods = false
		},
		handleCouponSubmit() {
			this.$refs.couponForm.validate(async (valid) => {
				if (valid) {
					let loading = this.$loading()
					var params = {
						...this.couponForm
					}
					if (params.sub_type === 1) {
						params.goods_ids = []
					}
					if (params.quota_type === 2) {
						params.quota = params.money * 1 + 0.01
					}
					if (params.sub_coupon.sub_type === 1) {
						params.sub_coupon.goods_ids = []
					}
					if (params.sub_coupon.quota_type === 2) {
						params.sub_coupon.quota = params.sub_coupon.money * 1 + 0.01
					}
					params.goods_ids = params.goods_ids.join(',')
					params.sub_coupon = JSON.stringify(params.sub_coupon)
					await createCoupon(params)
					this.$message.success(this.$t('seller.successMsg.add'))
					this.$router.push({ name: 'couponList', query: { type: 3 } })
					loading.close()
				} else {
					return false;
				}
			})
		}
	}
}
</script>

<style lang="scss" scoped>
.seller-card {
	.el-form-item {
		&:last-child {
			margin-bottom: 0;
		}
	}
	.w350 {
		width: 350px;
	}
	.tips {
		margin-left: 40px;

		.el-icon-warning {
			font-size: 16px;
			color: #ff7a45;
		}
	}
	.group-before {
		color: #666666;
		padding-right: 10px;
	}
	.group-after {
		color: #666666;
		padding-left: 10px;
	}
}

.page-bottom {
	margin-bottom: 20px;
}

.seller-dialog-scrollbar {
	height: 520px;
	.goods-item {
		display: flex;
		.pic {
			width: 60px;
			height: 60px;
			object-fit: cover;
		}

		.item-mian {
			flex: 1;
			width: 0;
			margin-left: 10px;
			line-height: 17px;

			.title {
				height: 34px;
				font-size: 14px;
				color: #666666;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}

			.number {
				height: 17px;
				margin-top: 9px;
				color: #333333;
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
</style>
